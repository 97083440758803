import {defineStore} from 'pinia'
import {emitirSnack} from '@/store/GlobalComponents'
import router from '@/router'

export const useSessionStore = defineStore('session', {
  persist: true,
  state: () => ({
    token: null,

    notificacoesLista: [],
    notificacoesCarregando: false,
    notificacoesUltimoCarregamentoTimestamp: 0,
  }),
  getters: {
    isAuthenticated: (state) => !!state.token,
    payload: (state) => {
      if (!state.token) return null
      const partes = state.token.split('.')
      if (partes.length !== 3) return null
      try {
        return JSON.parse(window.atob(partes[1]))
      } catch (e) {
        return null
      }
    },
  },
  actions: {
    logout() {
      if (this.token) {
        this.token = null
        this.notificacoesLista = []
        emitirSnack({color: 'success', text: 'Você foi desconectado'})
      }
      return router.replace({name: 'login'})
    },
    async carregarNotificacoes(repetir) {
      if (!this.token) return
      this.notificacoesCarregando = true
      const {httpSilent} = await import ('@/plugins/axios')
      try {
        if (repetir && Date.now() < (this.notificacoesUltimoCarregamentoTimestamp + 60000)) return //Ainda é muito cedo pra carregar repetitivamente
        const {data} = await httpSilent.get('/notificacoes')
        this.notificacoesLista = data.notificacoes
        this.notificacoesUltimoCarregamentoTimestamp = Date.now()
      } finally {
        this.notificacoesCarregando = false
        if (repetir) setTimeout(() => this.carregarNotificacoes(true), 60000);
      }
    },
  },
})
