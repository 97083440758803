import JsonPackage from '../../package.json'

/**
 * Limpa a localStorage se a versão do aplicativo mudar.
 * @return {boolean} - true: não houve alterações. false: houve alteração, cache resetado.
 */
export const checkVersion = () => {
  const x = localStorage.getItem('appVersion');
  const y = localStorage.getItem('appName');
  if ((!x || x !== JsonPackage.version) || (!y || y !== JsonPackage.name)) {
    localStorage.clear();
    localStorage.setItem('appVersion', JsonPackage.version);
    localStorage.setItem('appName', JsonPackage.name);
    return false;
  }
  return true;
}
