import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import pinia from '@/plugins/pinia'
import { checkVersion } from '@/plugins/persistance'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './assets/css/animations.css'
import './assets/css/app.css'
import './plugins/theMask'

Vue.config.productionTip = false

if (!checkVersion()) window.location.reload()

new Vue({
  pinia,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
