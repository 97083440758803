
const routes = [
  {
    path: '/auth',
    component: () => import('../layouts/AuthLayout.vue'),
    meta: { requiresNoAuth: true },
    children: [
      { path: 'login', name: 'login', alias: '/login', component: () => import('../views/auth/LoginPage.vue') },
      { path: 'criar-conta', name: 'criar-conta', component: () => import('../views/auth/CriarConta.vue') },
      { path: 'esqueci-senha', name: 'esqueci-senha', component: () => import('../views/auth/EsqueciSenha.vue') },
      { path: 'recuperar-senha', name: 'recuperar-senha', component: () => import('../views/auth/RecuperarSenha.vue') },
      //{ path: 'reset-password', name: 'reset-password', component: () => import('pages/auth/ResetPassword.vue') }, //Se o path sofrer mudança, atualize a função 'onSubmit' de EsqueciSenha.vue
    ],
  },

  {
    path: '/',
    component: () => import('../layouts/MainLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      { path: '', name: 'Home', alias: '/home', component: () => import('../views/HomePage.vue') },
      { path: 'releasenotes', name: 'release-notes', component: () => import('../views/NotasAtualizacao.vue') },
      { path: 'profile', name: 'Profile', component: () => import('../views/PerfilUsuario.vue') },
      { path: 'notificacoes', name: 'notificacoes', component: () => import('../views/NotificacoesPage.vue') },
      { path: 'ajuda', name: 'ajuda', component: () => import('../views/TutoriaisPage.vue') },

      { path: 'financeiro/cobrancas', name: 'financeiro-cobrancas', component: () => import('../views/financeiro/CobrancasLista.vue') },
      { path: 'financeiro/cartao/:item?', name: 'financeiro-cartao', component: () => import('../views/financeiro/CartaoCredito.vue') },
      { path: 'financeiro/parcelar', name: 'financeiro-parcelar', component: () => import('../views/financeiro/ParcelarCobranca.vue') },

      { path: 'novorequerimento/tipos', component: () => import('../views/requerimento/novo/EscolherTipo.vue') },
      { path: 'novorequerimento/formulario/:id', component: () => import('../views/requerimento/novo/PreencherFormulario.vue') },
      { path: 'requerimentos', name: 'requerimentos', component: () => import('../views/requerimento/RequerimentosMeus.vue') },
      { path: 'requerimento/:id', name: 'requerimento', component: () => import('../views/requerimento/RequerimentoDetalhes.vue'), props: true },

      { path: 'ouvidoria/novo', name: 'ouvidoria-novo', component: () => import('../views/ouvidoria/NovaManifestacao.vue') },
      { path: 'ouvidoria/manifestacoes', name: 'ouvidoria-manifestacoes', component: () => import('../views/ouvidoria/MinhasManifestacoes.vue') },
      { path: 'ouvidoria/manifestacao/:id', name: 'ouvidoria-manifestacao', component: () => import('../views/ouvidoria/ManifestacaoDetalhes.vue'), props: true },
      {
        path: 'ouvidoria/imprimir/manifestacao/:id',
        name: 'ouvidoria-imprimir-manifestacao',
        component: () => import('../views/ouvidoria/ImprimirManifestacao.vue'),
        meta: {
          hideAppBar: true,
          hideNavigationDrawer: true,
          hideFooter: true
        },
      },

      {
        path: 'atendente/requerimentos',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/requerimento/BuscaRequerimentos.vue'),
        meta: { tipo: 1 },
      },
      {
        path: 'atendente/relatorios/requerimentos-por-tipo',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/requerimento/relatorios/RequerimentosPorTipo.vue'),
        meta: { tipo: 1 },
      },
      {
        path: 'atendente/relatorios/requerimentos-por-localidade',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/requerimento/relatorios/RequerimentosPorLocalidade.vue'),
        meta: { tipo: 1 },
      },
      {
        path: 'ouvidoria/painel',
        name: 'ouvidoria-painel',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/ouvidoria/PainelOuvidoria.vue'),
        meta: { tipo: 1 },
      },
      {
        path: 'ouvidoria/categorias',
        name: 'ouvidoria-categorias',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/ouvidoria/ManifestacaoCategorias.vue'),
        meta: { tipo: 1 },
      },
      {
        path: 'usuarios',
        name: 'usuarios',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/usuarios/UsuariosPage.vue'),
        meta: { tipo: 1 },
      },
      {
        path: 'grupos',
        name: 'grupos',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/usuarios/GruposPage.vue'),
        meta: { tipo: 1 },
      },

      {
        path: 'grupo/:id',
        name: 'grupo',
        props: true,
        component: () => import(/* webpackChunkName: "restrito" */ '../views/usuarios/GrupoPage.vue'),
        meta: { tipo: 2 },
      },
      {
        path: 'relatorios/sessoes',
        name: 'relatorio-sessoes',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/relatorios/LogSessoes.vue'),
        meta: { tipo: 2 },
      },
      {
        path: 'relatorios/interacoes',
        name: 'relatorio-interacoes',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/relatorios/HistoricoInteracoes.vue'),
        meta: { tipo: 2 },
      },
      {
        path: 'relatorios/requerimentos',
        name: 'relatorio-requerimentos',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/relatorios/RelatorioRequerimentos.vue'),
        meta: { tipo: 2 },
      },
      {
        path: 'relatorios/manifestacoes-tempomedio',
        name: 'relatorio-manifestacoes-tempo',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/relatorios/OuvidoriaTempoMedio.vue'),
        meta: { tipo: 1 },
      },
      {
        path: 'relatorios/manifestacoes-por-cidade',
        name: 'relatorio-manifestacoes-por-cidade',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/relatorios/ManifestacoesPorCidade.vue'),
        meta: { tipo: 1 },
      },
      {
        path: 'relatorios/manifestacoes-por-categoria',
        name: 'relatorio-manifestacoes-por-categoria',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/relatorios/ManifestacoesPorCategoria.vue'),
        meta: { tipo: 1 },
      },
      {
        path: 'relatorios/manifestacoes-por-mes',
        name: 'relatorio-manifestacoes-por-mes',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/relatorios/ManifestacoesPorMes.vue'),
        meta: { tipo: 1 },
      },
      {
        path: 'config/requerimentos',
        name: 'configurar-requerimentos',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/requerimento/ConfigurarRequerimentos.vue'),
        meta: { tipo: 2 },
      },
      {
        path: 'config/status',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/configuracao/AppStatus.vue'),
        meta: { tipo: 2 },
      },
      {
        path: 'modelo/:id',
        name: 'modelo',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/requerimento/RequerimentoModelo.vue'),
        meta: { tipo: 2 },
        props: true,
      },
      {
        path: 'notificacoes/painel',
        name: 'notificacoes-painel',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/EmDesenvolvimento.vue'),
        meta: { tipo: 2 },
      },
      {
        path: 'configuracoes',
        name: 'MenuConfiguracoes',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/configuracao/MenuConfiguracoes.vue'),
        meta: { tipo: 2 },
      },
      {
        path: 'telainicial',
        name: 'TelaInicial',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/configuracao/TelaInicialHistorico.vue'),
        meta: { tipo: 2 },
      },
      {
        path: 'telainicial/editor',
        name: 'TelaInicialEditor',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/configuracao/TelaInicialEditor.vue'),
        meta: { tipo: 2 },
      },
      {
        path: 'configuracao/termo',
        name: 'configuracao-termo',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/configuracao/TermoDeUso.vue'),
        meta: { tipo: 3 },
      },
      {
        path: 'configuracao/termo/editar',
        name: 'configuracao-termo-editar',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/configuracao/TermoEditar.vue'),
        meta: { tipo: 3 },
      },
      {
        path: 'configuracao/videotutoriais',
        name: 'configuracao-videotutoriais',
        component: () => import(/* webpackChunkName: "restrito" */ '../views/configuracao/VideoTutoriais.vue'),
        meta: { tipo: 3 },
      },
    ]
  },


  {
    path: '*',
    name: '404',
    component: () => import('../views/NotFound.vue'),
  },
]

export default routes
