import {defineStore} from 'pinia'

export const useConfigStore = defineStore('config', {
  persist: true,
  state: () => ({
    configuracoes: null,
    timestamp: Date.now(),
    preferenciaFiltroStatus: null,  //usado na busca de requerimentos, memoriza a prefencia dos filtros
    preferenciaFiltroGrupo: null,   //||
    preferenciaFiltroTipo: null,    //||
    preferenciaFiltroData: null,    //||
    preferenciaTbCompacta: false,   //||
    preferenciaTbItensPorPagina: 15,//||
  }),
  actions: {
    async carregarConfiguracoes() {
      const {httpSilent} = await import('@/plugins/axios')
      if (!this.configuracoes || Date.now() > (this.timestamp + 300000)) {
        const {data} = await httpSilent.get('/configuracoes')
        this.configuracoes = data.configuracoes
        this.timestamp = Date.now()
      }
    },
  }
})
